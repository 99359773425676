import React from "react";
import { AttributionControl, Layer, Map, Source } from "react-map-gl";

import { mapboxToken } from "./mapboxToken";
import "../../css/Map.scss";

function ClientMap({ envelope }) {
  if (!envelope?.type) return null;
  return (
    <div className={"map-container"}>
      <Map
        mapboxAccessToken={mapboxToken}
        mapStyle="mapbox://styles/jfmass/cjlkel64s1sbr2sphocze86i9"
        attributionControl={false}
        initialViewState={{
          latitude: 46.25,
          longitude: -72,
          zoom: 4,
          maxBounds: [
            [-82, 43.5],
            [-60, 50],
          ],
        }}
      >
        <AttributionControl customAttribution={"Hydro Météo"} />
        <Source id="envelope" type="geojson" data={envelope}>
          <Layer
            id="envelope"
            type="fill"
            paint={{
              "fill-color": "transparent",
              "fill-outline-color": "#33b",
            }}
          />
        </Source>
      </Map>
    </div>
  );
}

export default ClientMap;
