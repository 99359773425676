import React from "react";

function Eula() {
  return (
    <div>
      <h1 className={"title is-1"}>EULA</h1>
    </div>
  );
}

export default Eula;
