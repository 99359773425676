import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Svg from "../Svg";

import PersonContext from "../../util/PersonContext";

import { ReactComponent as Bulletins } from "../../assets/bulletins.svg";
import { turquoise } from "../../sass/colors";

function BulletinsNav() {
  const { person } = useContext(PersonContext);
  if (!person?.clientHasWeatherSub()) return null;

  return (
    <div className={"has-dropright"}>
      <div className={"dropright-header"}>
        <Svg color={"white"} size={"1.5rem"} Component={Bulletins} />
        <span>Bulletins</span>
      </div>
      <div className="dropright">
        <div className={"nav-section"}>
          <div className={"nav-section-header"}>
            <Svg size={"1.5rem"} color={turquoise} Component={Bulletins} />
            <h1>Bulletins</h1>
          </div>
          <Link
            className="dropright-item"
            to={`/bulletins`}
            onClick={(e) => e.target.blur()}
          >
            Bulletins en cours
          </Link>
          {person?.isScientist() && (
            <>
              <Link
                className="dropright-item"
                to={`/bulletins/new`}
                onClick={(e) => e.target.blur()}
              >
                Créer un bulletin
              </Link>
              <Link
                className="dropright-item"
                to={`/bulletins/history`}
                onClick={(e) => e.target.blur()}
              >
                Historique des bulletins
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BulletinsNav;
