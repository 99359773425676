import React, { useContext, useEffect, useState } from "react";
import {
  faCamera,
  faDroplet,
  faSnowflake,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import Checkbox from "../../components/Checkbox";
import ClientMap from "../maps/ClientMap";
import DatePicker from "../../components/DatePicker";
import MultiEmailInput from "../../components/MultiEmailInput";
import MultiSelect from "../../components/MultiSelect";
import Select from "../../components/Select";
import Waiting from "../../components/Waiting";

import { blue } from "../../sass/colors";
import PersonContext from "../../util/PersonContext";
import scribe from "../../util/scribe";
import xhr from "../../services/xhr";

import "./Client.css";

const datify = (client) => {
  for (const service of ["stationsService", "weatherService", "iceService"]) {
    for (const border of ["beginning", "end"]) {
      if (client?.[service]?.[border])
        client[service][border] = new Date(client[service][border]);
    }
  }
};

function StationType({ stationType }) {
  switch (stationType) {
    case "Météorologique":
      return <FontAwesomeIcon icon={faSnowflake} color={blue} />;
    case "Hydrologique - niveau":
      return (
        <FontAwesomeIcon
          icon={faDroplet}
          color={blue}
          title={"Hydrologique - niveau"}
        />
      );
    case "Hydrologique - débit":
      return (
        <FontAwesomeIcon
          icon={faDroplet}
          color={blue}
          title={"Hydrologique - débit"}
        />
      );
    case "Caméra":
      return <FontAwesomeIcon icon={faCamera} color={blue} />;
    default:
      return <span>{stationType}</span>;
  }
}

function StationInfo({ station, dis }) {
  return (
    <div className={"station-info"}>
      <span
        className={`circle ${station.active ? "active" : "inactive"}`}
        title={station.active ? "active" : "inactive"}
      ></span>
      <span>#{station.id}</span>
      <span>
        <StationType stationType={station.stationType} />
      </span>
      <span>{station.name}</span>
      <span>
        ({dis.find((di) => di.id === station.dataIngestion)?.name || ""})
      </span>
    </div>
  );
}

const isPublic = (station) => {
  return [7, 10, 11, 12, 13].includes(station.dataIngestion);
};

const isChecked = (station, checkedStations) => {
  return !!(isPublic(station) || checkedStations?.includes(station.id));
};

const isDisabled = (station) => {
  return !station.active || isPublic(station);
};

function Client() {
  const [loading, setLoading] = useState(true);
  const [persons, setPersons] = useState([]);
  const [stations, setStations] = useState([]);
  const [dis, setDis] = useState([]);
  const [iceGraphs, setIceGraphs] = useState([]);
  const [client, setClient] = useState(null);
  const [errors, setErrors] = useState({});
  const { person } = useContext(PersonContext);
  let { id } = useParams();

  useEffect(() => {
    if (person?.isAdmin() && id) {
      const promises = [];
      promises.push(xhr.get("/persons"));
      promises.push(xhr.get("/clients/" + id));
      promises.push(xhr.get("/stations"));
      promises.push(xhr.get("/stations/data-ingestion-schemes"));
      promises.push(xhr.get("/stations/ice"));
      Promise.all(promises)
        .then(([ps, c, stns, ds, igs]) => {
          setPersons(
            ps.map((p) => ({
              id: p.id,
              name: `#${p.id} ${p.firstName} ${p.lastName}`,
            }))
          );
          datify(c);
          setClient(c);
          setStations(stns);
          setDis(ds);
          setIceGraphs(igs);
          setLoading(false);
        })
        .catch(scribe.error);
    }
  }, [id, person]);

  if (!person?.isAdmin()) return null;

  if (loading) return <Waiting />;

  const { name } = client;

  const handleSave = async () => {
    try {
      const cp = structuredClone(client);
      delete cp.id;
      delete cp.name;
      delete cp.geom;
      delete cp.apiKey;
      const c = await xhr.put("/clients/" + client.id, cp);
      datify(c);
      setClient(c);
      toast.success("Mise à jour réussie!");
    } catch (e) {
      toast.error(
        "Erreur lors de la mise à jour, envoyez le contenu de ctrl+shift+i > Console à votre administratrice système"
      );
      console.error(e?.response || e);
    }
  };

  const handleChange = (name, value) => {
    const cp = structuredClone(client);
    const err = structuredClone(errors);
    if (name.includes(".")) {
      const [service, field] = name.split(".");
      cp[service][field] = value;
      if (cp[service].service) {
        if (!cp[service].beginning) {
          err[`${service}Beginning`] =
            "La date de début est requise si l'abonnement est actif";
        } else {
          err[`${service}Beginning`] = null;
        }
        if (!cp[service].end) {
          err[`${service}End`] =
            "La date de fin est requise si l'abonnement est actif";
        } else {
          err[`${service}End`] = null;
        }
      } else {
        err[`${service}Beginning`] = null;
        err[`${service}End`] = null;
      }
    } else if (!!name.match(/client-station-checkbox-\d+/)) {
      let [, id] = name.match(/client-station-checkbox-(\d+)/);
      id = Number(id);
      if (value) {
        cp.stationsService.stations.push(id);
      } else {
        const idx = cp.stationsService.stations.findIndex((i) => i === id);
        if (idx >= 0) cp.stationsService.stations.splice(idx, 1);
      }
    } else if (name === "iceGraphs") {
      if (!cp.iceService) cp.iceService = {};
      cp.iceService.graphs = value;
    } else if (name === "bulletinRecipients") {
      if (!cp.weatherService) cp.weatherService = {};
      cp.weatherService.bulletinRecipients = value;
    } else {
      cp[name] = value;
    }
    setClient(cp);
    setErrors(err);
  };

  const globallyValid = Object.values(errors).filter((v) => !!v).length === 0;

  return (
    <div className={"content"}>
      <h1 className={"title is-1"}>
        #{id} {name}
      </h1>
      <button
        className={`button is-primary is-narrow`}
        onClick={handleSave}
        disabled={!globallyValid}
      >
        Sauvegarder
      </button>
      <div className={"columns"}>
        <div className={"column"}>
          <h2 className={"title is-2"}>Personnes ayant accès</h2>
          <MultiSelect
            name={"employees"}
            options={persons}
            value={client.employees}
            label={"Personnes associées"}
            onChange={handleChange}
          />
          <h2 className={"title is-2"}>Responsable</h2>
          <Select
            label={"Responsable"}
            name={"pocs"}
            options={persons}
            value={client.pocs?.[0]}
            onChange={(name, val) => handleChange(name, [val])}
          />
          <h2 className={"title is-2"}>Enveloppe géographique</h2>
          <ClientMap envelope={client?.geojson} />
        </div>
        <div className={"column"}>
          <h2 className={"title is-2"}>Services</h2>
          <label className={"label"}>Stations télémétriques</label>
          <div className={"columns is-vcentered"} style={{ minHeight: 104 }}>
            <div className={"column"}>
              <Checkbox
                name={"stationsService.service"}
                value={!!client?.stationsService?.service}
                onChange={handleChange}
              >
                Abonnement
              </Checkbox>
            </div>
            <div className={"column"}>
              {!!client?.stationsService?.service && (
                <DatePicker
                  label={"Début"}
                  name={"stationsService.beginning"}
                  value={client.stationsService.beginning}
                  onChange={handleChange}
                  error={errors.stationsServiceBeginning}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
            <div className={"column"}>
              {!!client?.stationsService?.service && (
                <DatePicker
                  label={"Fin"}
                  name={"stationsService.end"}
                  value={client.stationsService.end}
                  onChange={handleChange}
                  error={errors.stationsServiceEnd}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
          </div>
          <p className={"help"}>
            Les stations accessibles au client peuvent être sélectionnées dans
            la liste au bas de la page
          </p>
          <label className={"label"}>S&P Météo</label>
          <div className={"columns is-vcentered"} style={{ minHeight: 144 }}>
            <div className={"column"}>
              <Checkbox
                name={"weatherService.service"}
                value={!!client?.weatherService?.service}
                onChange={handleChange}
              >
                Abonnement
              </Checkbox>
            </div>
            <div className={"column"}>
              {!!client?.weatherService?.service && (
                <DatePicker
                  label={"Début"}
                  name={"weatherService.beginning"}
                  value={client.weatherService.beginning}
                  onChange={handleChange}
                  error={errors.weatherServiceBeginning}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
            <div className={"column"}>
              {!!client?.weatherService?.service && (
                <DatePicker
                  label={"Fin"}
                  name={"weatherService.end"}
                  value={client.weatherService.end}
                  onChange={handleChange}
                  error={errors.weatherServiceEnd}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
          </div>
          <label className={"label"}>S&P Hydro/glacio</label>
          <div className={"columns is-vcentered"} style={{ minHeight: 144 }}>
            <div className={"column"}>
              <Checkbox
                name={"iceService.service"}
                value={!!client?.iceService?.service}
                onChange={handleChange}
              >
                Abonnement
              </Checkbox>
            </div>
            <div className={"column"}>
              {!!client?.iceService?.service && (
                <DatePicker
                  label={"Début"}
                  name={"iceService.beginning"}
                  value={client.iceService.beginning}
                  onChange={handleChange}
                  error={errors.iceServiceBeginning}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
            <div className={"column"}>
              {!!client?.iceService?.service && (
                <DatePicker
                  label={"Fin"}
                  name={"iceService.end"}
                  value={client.iceService.end}
                  onChange={handleChange}
                  error={errors.iceServiceEnd}
                  showTimeSelect
                  dateFormat={"yyyy-MM-dd HH:mm"}
                  timeFormat={"HH:mm"}
                />
              )}
            </div>
          </div>
          <MultiSelect
            label={"Graphiques de glace accessibles"}
            name={"iceGraphs"}
            value={client?.iceService?.graphs || []}
            options={iceGraphs}
            onChange={handleChange}
            isDisabled={!client?.iceService?.service}
          />
          <MultiEmailInput
            label={"Destinataires des bulletins"}
            name={"bulletinRecipients"}
            emails={client.weatherService?.bulletinRecipients || []}
            onChange={handleChange}
          />
        </div>
      </div>
      <h2 className={"title is-2"}>Stations accessibles</h2>
      <div className={"columns is-multiline is-compact is-striped"}>
        {stations.map((station) => (
          <div
            key={`client-station-${station.id}`}
            className={"column is-half-desktop is-full-tablet is-full-mobile"}
          >
            <Checkbox
              name={`client-station-checkbox-${station.id}`}
              value={isChecked(station, client?.stationsService?.stations)}
              disabled={isDisabled(station)}
              onChange={handleChange}
            >
              <StationInfo station={station} dis={dis} />
            </Checkbox>
          </div>
        ))}
      </div>
      <button
        className={`button is-primary is-narrow`}
        onClick={handleSave}
        disabled={!globallyValid}
      >
        Sauvegarder
      </button>
    </div>
  );
}

export default Client;
