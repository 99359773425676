import React from "react";
import Dp from "react-datepicker";

import "react-datepicker/dist/react-datepicker.min.css";
import "./DateRangePicker/DateRangePicker.css";

function DatePicker({ label, name, value, onChange, error, ...rest }) {
  return (
    <div className={"field"}>
      <label className={"label"}>{label}</label>
      <div className={"control"}>
        <Dp
          dateFormat={"yyyy-MM-dd"}
          selected={value}
          onChange={(date) => onChange(name, date)}
          {...rest}
        />
      </div>
      {error ? <p className={"help is-danger"}>{error}</p> : null}
    </div>
  );
}

export default DatePicker;
