import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Highcharts from "highcharts";
import annotations from "highcharts/modules/annotations";
import exportingFn from "highcharts/modules/exporting";
import exportingDataFn from "highcharts/modules/export-data";
import moreFn from "highcharts/highcharts-more";
import draggable from "highcharts/modules/draggable-points";

import Nav from "./components/Nav/Nav";
import Social from "./components/Nav/Social";

import auth from "./services/auth";
import HcContext from "./util/HcContext";
import PersonContext from "./util/PersonContext";

import background from "../src/assets/background.png";
import "./App.scss";

annotations(Highcharts);
moreFn(Highcharts);
exportingFn(Highcharts);
exportingDataFn(Highcharts);
draggable(Highcharts);

function App() {
  const [person, setPerson] = useState(null);
  const [hc, setHc] = useState(null);

  useEffect(() => {
    auth.getPerson().then((p) => setPerson(p));

    Highcharts.setOptions({
      credits: false,
      accessibility: { enabled: false },
      exporting: {
        enabled: false,
      },
      title: {
        text: "",
      },
      chart: {
        height: 600,
      },
      tooltip: {
        valueDecimals: 2,
      },
    });
    setHc(Highcharts);
  }, []);

  return (
    <PersonContext.Provider value={{ person, setPerson }}>
      <HcContext.Provider value={{ hc }}>
        <div
          id="bg"
          style={{
            backgroundImage: `url(${background})`,
          }}
        />
        <div id="main-container">
          <Nav />
          <div id="main-content-wrapper">
            <Social />
            <Outlet />
            <ToastContainer position={"bottom-right"} />
          </div>
        </div>
      </HcContext.Provider>
    </PersonContext.Provider>
  );
}

export default App;
