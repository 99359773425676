import React from "react";

function MultiEmailInput({ label, name, emails, error, onChange }) {
  emails = emails || [];
  return (
    <>
      <div className={"field"}>
        <label className={"label"}>{label}</label>
      </div>
      {emails.map((email, idx) => (
        <div key={`email-${idx}`} className={"field has-addons"}>
          <div className={"control"} style={{ flexGrow: 1 }}>
            <input
              className={"input"}
              type={"email"}
              placeholder={"abc@ville.ca"}
              value={email}
              onChange={({ currentTarget: me }) => {
                const cp = [...emails];
                cp[idx] = me.value;
                onChange(name, cp);
              }}
            />
          </div>
          <div className={"control"}>
            <button
              className={"button is-narrow is-danger"}
              onClick={() => {
                const cp = [...emails];
                cp.splice(idx, 1);
                onChange(name, cp);
              }}
            >
              -
            </button>
          </div>
        </div>
      ))}
      <button
        className={"button is-narrow is-primary"}
        onClick={() => {
          const cp = [...emails];
          cp.push("");
          onChange(name, cp);
        }}
      >
        +
      </button>
      {error ? <p className={"help is-danger"}>{error}</p> : null}
    </>
  );
}

export default MultiEmailInput;
