let scribe;

switch (process.env.NODE_ENV) {
  default:
    scribe = {
      notify: (e) => console.error(e),
      error: (e) => console.error(e),
    };
}

module.exports = scribe;
