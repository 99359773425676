import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Waiting from "../../components/Waiting";

import { BetterDate } from "@hydro-meteo/utils";
import PersonContext from "../../util/PersonContext";
import scribe from "../../util/scribe";
import xhr from "../../services/xhr";

import "./clientTable.css";

function ClientsList() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const { person } = useContext(PersonContext);

  useEffect(() => {
    if (person) {
      xhr
        .get("/clients")
        .then((cs) => {
          setClients(cs);
          setLoading(false);
        })
        .catch(scribe.error);
    }
  }, [person]);

  if (!person?.isAdmin()) return null;

  if (loading) return <Waiting />;

  return (
    <div className={"content"}>
      <h1 className={"title is-1"}>Clients</h1>
      <Link
        className={"button is-primary"}
        to={"new"}
        style={{ marginBottom: "1rem" }}
      >
        Créer un nouveau client
      </Link>
      <table className={"table is-striped client-table"}>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>Nom</th>
            <th colSpan={3}>S&P Météo</th>
            <th colSpan={3}>S&P Hydro/glacio</th>
            <th colSpan={3}>Stations</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={`client-${client.id}`}>
              <td>
                <Link to={`/clients/${client.id}`}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "1.8rem",
                      textAlign: "right",
                      marginRight: "0.5rem",
                    }}
                  >
                    #{client.id}
                  </span>
                  {client.name}
                </Link>
              </td>
              <td>{client?.weatherService?.service ? "✓" : ""}</td>
              <td>
                {client?.weatherService?.beginning
                  ? new BetterDate(client.weatherService.beginning).format(
                      "YYYY-MM-DD @ America/Montreal"
                    )
                  : ""}
              </td>
              <td>
                {client?.weatherService?.end
                  ? new BetterDate(client.weatherService.end).format(
                      "YYYY-MM-DD @ America/Montreal"
                    )
                  : ""}
              </td>
              <td>{client.iceService.service ? "✓" : ""}</td>
              <td>
                {client?.iceService?.beginning
                  ? new BetterDate(client.iceService.beginning).format(
                      "YYYY-MM-DD @ America/Montreal"
                    )
                  : ""}
              </td>
              <td>
                {client?.iceService?.end
                  ? new BetterDate(client.iceService.end).format(
                      "YYYY-MM-DD @ America/Montreal"
                    )
                  : ""}
              </td>
              <td>{client.stationsService.service ? "✓" : ""}</td>
              <td>
                {client?.stationsService?.beginning
                  ? new BetterDate(client.stationsService.beginning).format(
                      "YYYY-MM-DD @ America/Montreal"
                    )
                  : ""}
              </td>
              <td>
                {client?.stationsService?.end
                  ? new BetterDate(client.stationsService.end).format(
                      "YYYY-MM-DD @ America/Montreal"
                    )
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ClientsList;
