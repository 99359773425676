import React from "react";

function Checkbox({
  name,
  value,
  error,
  onChange,
  children,
  title = null,
  ...rest
}) {
  return (
    <div className={"field"}>
      <div className={"control"}>
        <label className={"checkbox"} title={title}>
          <input
            id={name}
            name={name}
            type={"checkbox"}
            checked={!!value}
            onChange={({ currentTarget: me }) => {
              onChange(me.name, me.checked);
            }}
            {...rest}
          />
          {children}
        </label>
      </div>
      {error ? <p className={"help is-danger"}>{error}</p> : null}
    </div>
  );
}

export default Checkbox;
