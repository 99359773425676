import React from "react";
import Lottie from "lottie-react";
import animation from "../assets/79611-water-animation.json";

function Waiting() {
  return (
    <div className={"is-clipped"}>
      <div className={"modal is-active"}>
        <div className={"modal-background"} />
        <div className={"modal-content"}>
          <Lottie
            style={{
              position: "relative",
            }}
            animationData={animation}
            autoplay={true}
            loop={true}
          />
        </div>
      </div>
    </div>
  );
}

export default Waiting;
