import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import AdminNav from "./AdminNav";
import ForecastNav from "./ForecastNav";
import BulletinsNav from "./BulletinsNav";
import ObservationsNav from "./ObservationsNav";

import "./Nav.scss";

import logo from "../../assets/hydrometeo_logo_small.png";
import PersonContext from "../../util/PersonContext";
import { turquoise } from "../../sass/colors";

function Nav() {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const { pathname } = useLocation();
  const { person } = useContext(PersonContext);

  useEffect(() => {
    setBurgerOpen(false);
  }, [pathname]);

  return (
    <nav
      className="left-nav is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div>
        <Link className={"logo"} to="/">
          <div
            style={{
              backgroundColor: "white",
              width: 60,
              height: 60,
              borderRadius: "30px",
              borderColor: turquoise,
              borderStyle: "solid",
              borderWidth: "3px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <img src={logo} width="30" height="30" alt="Logo d'Hydro Météo" />
          </div>
        </Link>

        <button
          className={`navbar-burger${burgerOpen ? " is-active" : ""}`}
          aria-label="menu"
          aria-expanded={burgerOpen}
          data-target="navbarMenu"
          onClick={() => setBurgerOpen(!burgerOpen)}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </button>
      </div>

      <div
        id="navbarMenu"
        className={`navbar-menu${burgerOpen ? " is-active" : ""}`}
      >
        <div className="navbar-start">
          <ObservationsNav />
          <ForecastNav />
          {person?.clientHasWeatherSub() && <BulletinsNav />}
        </div>
        {person?.isHydroMeteo() && <AdminNav />}
      </div>
    </nav>
  );
}

export default Nav;
