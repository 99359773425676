export const blue = "#003F61";
export const turquoise = "#157EB8";
export const yellow = "#F1DC1E";
export const red = "#F11E1E";
export const orange = "#F2B400";
export const green = "#07CC2E";
export const whiteBis = "#F4F6FB";
export const greyed = "#ececec";
export const paleTurquoise = "#e4f5ff";
export const midTurquoise = "#bee8ff";
export const lilac = "#beaed4";

const colors = {
  blue,
  turquoise,
  yellow,
  red,
  orange,
  green,
  whiteBis,
  greyed,
  paleTurquoise,
  midTurquoise,
  lilac,
};
export default colors;
