import React from "react";
import Sel from "react-select";

function Select({
  name,
  options,
  value,
  label,
  onChange,
  error = null,
  title = null,
  ...rest
}) {
  options = options || [];
  // options is [{id, name}]
  // value is id or [{value, label}]
  // onChange is (newvalue)=>{...}
  const reoptions = options.map((o) => {
    return { value: o.id, label: o.name, ...o };
  });
  const revalue = rest?.isMulti
    ? value || null
    : reoptions.find((ro) => ro.value === value) || null;
  // undefined doesn't clear the native react select below, it needs to be NULL

  return (
    <div className={"field"} title={title}>
      <label className={"label"}>{label}</label>
      <div className={"control"}>
        <Sel
          isClearable={true}
          isSearchable={true}
          options={reoptions}
          value={revalue}
          onChange={(a) => {
            onChange(
              name,
              (a && a.value) || (a && Array.isArray(a) && a) || null
            );
          }}
          onClear={(a) => console.log("cleared?", a)}
          {...rest}
        />
      </div>
      {error ? <p className={"help is-danger"}>{error}</p> : null}
    </div>
  );
}

export default Select;
