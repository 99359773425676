import React, { useEffect, useState } from "react";

function TextInput({
  label,
  name,
  placeholder,
  value,
  error,
  onChange,
  type = "text",
  disabled = false,
  title = null,
}) {
  // to avoid caret jumping, use sync cache
  const [cache, setCache] = useState(value);
  const updateVal = (val) => {
    setCache(val);
    onChange(name, val);
  };
  useEffect(() => {
    if (type === "number") setCache(value);
    else if (value) setCache(value);
  }, [type, value]);
  return (
    <div className={"field"}>
      <label className={"label"}>{label}</label>
      <div className={"control"}>
        <input
          className={"input"}
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          value={type === "number" ? cache : cache || ""}
          onChange={({ currentTarget: me }) => updateVal(me.value)}
          disabled={disabled}
          title={title}
        />
      </div>
      {error ? <p className={"help is-danger"}>{error}</p> : null}
    </div>
  );
}

export default TextInput;
