import React, { useContext } from "react";
import { faGaugeHigh, faUser, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Svg from "../Svg";

import { ReactComponent as Administration } from "../../assets/administration.svg";
import { ReactComponent as Forecast } from "../../assets/forecast.svg";
import { ReactComponent as Snowflake } from "../../assets/snowflake.svg";
import { turquoise } from "../../sass/colors";

import PersonContext from "../../util/PersonContext";

function AdminNav() {
  const { person } = useContext(PersonContext);
  if (!person?.isHydroMeteo()) return null;
  return (
    <div className="navbar-end">
      <div className={"has-dropright"}>
        <div className={"dropright-header"}>
          <Svg color={"white"} size={"1.5rem"} Component={Administration} />
          <span>Administration</span>
        </div>
        <div className="dropright">
          {person?.isAdmin() && (
            <div className={"nav-section"}>
              <div className={"nav-section-header"}>
                <FontAwesomeIcon
                  icon={faUser}
                  color={turquoise}
                  style={{ fontSize: "1.5rem" }}
                />
                <h1>Utilisateurs</h1>
              </div>
              <Link
                className="dropright-item"
                to={`/persons`}
                onClick={(e) => e.target.blur()}
              >
                Personnes
              </Link>
              <Link
                className="dropright-item"
                to={`/clients`}
                onClick={(e) => e.target.blur()}
              >
                Clients
              </Link>
            </div>
          )}
          {person?.isTechnical() && (
            <div className={"nav-section"}>
              <div className={"nav-section-header"}>
                <FontAwesomeIcon
                  icon={faGaugeHigh}
                  color={turquoise}
                  style={{ fontSize: "1.5rem" }}
                />
                <h1>Stations</h1>
              </div>
              <Link
                className={"dropright-item"}
                to={"/states/stations"}
                onClick={(e) => e.target.blur()}
              >
                État des stations
              </Link>
              <Link
                className={"dropright-item"}
                to={"/states/cameras"}
                onClick={(e) => e.target.blur()}
              >
                État des caméras
              </Link>
              <Link
                className="dropright-item"
                to={`/stations/edition`}
                onClick={(e) => e.target.blur()}
              >
                Gestion des stations
              </Link>
              <Link
                className="dropright-item"
                to={`/stations/solinst`}
                onClick={(e) => e.target.blur()}
              >
                Gestion des contrôleurs Solinst
              </Link>
              <Link
                className={"dropright-item"}
                to={"/stations/solinst/validation"}
                onClick={(e) => e.target.blur()}
              >
                Aide à la validation des données Solinst
              </Link>
            </div>
          )}
          <div className={"nav-section"}>
            <div className={"nav-section-header"}>
              <Svg size={"1.5rem"} color={turquoise} Component={Snowflake} />
              <h1>Glaciologie</h1>
            </div>
            <Link
              className={"dropright-item"}
              to={"stations/ice/report"}
              onClick={(e) => e.target.blur()}
            >
              Rapport de glace
            </Link>
          </div>
          {person?.isScientist() && (
            <div className={"nav-section"}>
              <div className={"nav-section-header"}>
                <Svg size={"1.5rem"} color={turquoise} Component={Forecast} />
                <h1>Prévisions</h1>
              </div>
              <Link
                className="dropright-item"
                to={`/pegase/correction`}
                onClick={(e) => e.target.blur()}
              >
                PEGASE - correction manuelle
              </Link>
              <Link
                className={"dropright-item"}
                to={"gleaubal-bis"}
                onClick={(e) => e.target.blur()}
              >
                "Gleaubal" bis
              </Link>
              {/*<Link*/}
              {/*  className={"dropright-item"}*/}
              {/*  to={"gleaubal"}*/}
              {/*  onClick={(e) => e.target.blur()}*/}
              {/*>*/}
              {/*  Gleaubal*/}
              {/*</Link>*/}
            </div>
          )}
          <div className={"nav-section"}>
            <div className={"nav-section-header"}>
              <FontAwesomeIcon
                icon={faLink}
                color={turquoise}
                style={{ fontSize: "1.5rem" }}
              />
              <h1>Liens externes</h1>
            </div>
            <a
              className="dropright-item"
              href={`https://www.wpc.ncep.noaa.gov/basicwx/basicwx_ndfd.php`}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Surface et fronts
            </a>
            <a
              className={"dropright-item"}
              href={`https://www.pivotalweather.com/model.php`}
            >
              Pivotal Weather
            </a>
            <a
              className={"dropright-item"}
              href={`https://proa.accuweather.com/pro/models-pro-menu.asp`}
            >
              AccuWeather multi modèles
            </a>
            <a
              className={"dropright-item"}
              href={`http://modele.hydrometeo.net/interne/`}
            >
              Modèle HM-WRF
            </a>
            <a
              className={"dropright-item"}
              href={`https://meteo.gc.ca/ensemble/naefs/semaine2_combinee_f.html`}
            >
              Long terme SPÉNA
            </a>
            <a
              className={"dropright-item"}
              href={`http://wxmaps.org/pix/temp1`}
            >
              Long terme, températures et précipitations
            </a>
            <a
              className={"dropright-item"}
              href={`https://meteocentre.com/numerical-weather-prediction/precipitation-accumulation.php?mod=cmc_gdps&run=12&type=SN&hi=000&hf=240&mode=latest&lang=fr&map=qc`}
            >
              Multi modèles Météo Centre
            </a>
            <a
              className={"dropright-item"}
              href={`http://modele.hydrometeo.net/interne/`}
            >
              HM-WRF cartes spécialisées
            </a>
            <a
              className={"dropright-item"}
              href={`https://apps.sentinel-hub.com/sentinel-playground/?source=S2L2A&lat=45.38591285563495&lng=-73.72100830078125&zoom=9&preset=1_TRUE_COLOR&layers=B01,B02,B03&maxcc=20&gain=1.0&gamma=1.0&time=2021-07-01%7C2022-01-28&atmFilter=&showDates=false`}
            >
              Observations satellitaires SentinelHub
            </a>
            <a
              className={"dropright-item"}
              href={`https://soshydro.net/epaisseur_glace`}
            >
              Graphiques de glace
            </a>
            <a
              className={"dropright-item"}
              href={`https://www.spc.noaa.gov/products/outlook/`}
            >
              Convective outlook SPC
            </a>
            <a
              className={"dropright-item"}
              href={`https://soshydro.net/evouala/edit/carte-risque`}
            >
              SOS Hydro
            </a>
            <a
              className={"dropright-item"}
              href={`https://hydrometeo.net/alertes_meteo.php`}
            >
              Avertissements météo en vigueur
            </a>
            <a
              className={"dropright-item"}
              href={`https://meteocentre.com/radar/weather-radar.php?lang=fr&area=qc&map=ref&anim=1&refresh=120`}
            >
              Radar composite
            </a>
            <a
              className={"dropright-item"}
              href={`https://meteocentre.com/satellite/imagery.php?lang=fr&area=ne&map=_ir&siz=&anim=1&refresh=450`}
            >
              Imagerie satellitaire
            </a>
            <a
              className={"dropright-item"}
              href={`https://meteocentre.com/surface-weather-station/map-synop.php?lang=fr&area=qc&type=precip`}
            >
              Accumulations (Env. Canada)
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminNav;
