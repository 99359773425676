import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import TextInput from "../../components/TextInput";

import PersonContext from "../../util/PersonContext";
import scribe from "../../util/scribe";
import xhr from "../../services/xhr";

const nameSchema = yup
  .string()
  .required("Vous devez choisir un nom pour le client");

function ClientCreator() {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const { person } = useContext(PersonContext);
  const navigate = useNavigate();

  if (!(person && person.isAdmin())) return null;

  const handleNameChange = async (name, val) => {
    setName(val);
    try {
      await nameSchema.validate(val);
      setNameError(null);
      setIsValid(true);
    } catch (e) {
      setNameError(e.message);
      setIsValid(false);
    }
  };

  const createClient = async () => {
    try {
      const created = await xhr.post("/clients", { name });
      toast.success("Client créé avec succès");
      navigate(`/clients/${created.id}`);
    } catch (e) {
      scribe.error(e?.response || e?.data || e);
      const msg =
        e?.response?.data?.message ||
        e?.data?.message ||
        "Erreur inattendue, contactez votre administratrice système";
      toast.error(msg);
    }
  };

  return (
    <div className={"content is-centered"}>
      <h1 className={"title is-1"}>Nouveau client</h1>
      <TextInput
        label={"Nom"}
        placeholder={"Ville de..."}
        name={"zename"}
        value={name}
        error={nameError}
        onChange={handleNameChange}
      />
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <button
          className={"button is-primary"}
          onClick={createClient}
          disabled={!isValid}
        >
          Créer le client
        </button>
      </div>
    </div>
  );
}

export default ClientCreator;
