import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

import Person from "../util/Person";
import scribe from "../util/scribe";
import xhr from "./xhr";

const key = "soshydro:token";

const register = async (account) => {
  try {
    if (!account.phone) delete account.phone;
    account.email = account.email.toLowerCase();
    const { token } = await xhr.post("/persons", account);
    xhr.setJwt(token);
    localStorage.setItem(key, token);
    return await Person.initialize(jwtDecode(token));
  } catch (e) {
    console.error(e);
    toast.error(e?.data?.message || "Erreur inattendue");
    return false;
  }
};

const login = async (credentials) => {
  try {
    credentials.email = credentials.email.toLowerCase();
    const { token } = await xhr.post("/persons/login", credentials);
    xhr.setJwt(token);
    localStorage.setItem(key, token);
    return await Person.initialize(jwtDecode(token));
  } catch (e) {
    console.error(e);
    toast.error(e?.data?.message || "Erreur inattendue");
    return false;
  }
};

const logout = () => {
  localStorage.removeItem(key);
};

const getPerson = async () => {
  try {
    const jwt = localStorage.getItem(key);
    if (jwt) {
      xhr.setJwt(jwt);
      return await Person.initialize(jwtDecode(jwt));
    } else return null;
  } catch (e) {
    scribe.error(e);
  }
};

const requestResetEmail = async (email) => {
  try {
    await xhr.post("/persons/reset", { email });
    return true;
  } catch (e) {
    console.error(e);
    toast.error(e?.data?.message || "Erreur inattendue");
    return false;
  }
};

const doReset = async (info) => {
  try {
    const { id, token: resetToken, password, passwordConfirmation } = info;
    const url = `/persons/reset/${id}/${resetToken}`;
    const { token } = await xhr.post(url, { password, passwordConfirmation });
    xhr.setJwt(token);
    localStorage.setItem(key, token);
    return await Person.initialize(jwtDecode(token));
  } catch (e) {
    console.error(e);
    toast.error(e?.data?.message || "Erreur inattendue");
    return false;
  }
};

const update = async (info) => {
  try {
    const { token } = await xhr.put("/persons/me", info);
    xhr.setJwt(token);
    localStorage.setItem(key, token);
    return await Person.initialize(jwtDecode(token));
  } catch (e) {
    console.error(e);
    toast.error(e?.data?.message || "Erreur inattendue");
    return false;
  }
};

const exports = {
  register,
  login,
  logout,
  getPerson,
  requestResetEmail,
  doReset,
  update,
};

export default exports;
