import React from "react";

function Svg({ size, color, Component }) {
  return (
    <Component
      fill={color}
      style={{ fontSize: size }}
      width={size}
      height={size}
    />
  );
}

export default Svg;
