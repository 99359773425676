import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import Client from "./routes/clients/Client";
import ClientCreator from "./routes/clients/ClientCreator";
import Clients from "./routes/clients/Clients";
import ClientsList from "./routes/clients/ClientsList";
import Eula from "./routes/Eula";
import Waiting from "./components/Waiting";

// note to self: don't reorder these in alphabetical order you CDO
import "./css/mystyles.css";
import "./css/index.scss";

const HydroStationCard = lazy(() =>
  import("./routes/stationsHydro/HydroStationCard")
);
const Camera = lazy(() => import("./routes/stationsCameras/Camera"));
const Cameras = lazy(() => import("./routes/stationsCameras/Cameras"));
const OneCamera = lazy(() => import("./routes/stationsCameras/OneCamera"));
const TempGauge = lazy(() =>
  import("./routes/stationsWeather/WeatherStationCard")
);

const WeatherStations = lazy(() =>
  import("./routes/stationsWeather/WeatherStations")
);
const WeatherStation = lazy(() =>
  import("./routes/stationsWeather/WeatherStation")
);
const HydroStations = lazy(() =>
  import("./routes/stationsHydro/HydroStations")
);
const HydroStation = lazy(() => import("./routes/stationsHydro/HydroStation"));

const IceStations = lazy(() => import("./routes/stationsIce/IceStations"));
const IceStation = lazy(() => import("./routes/stationsIce/IceStation"));
const IceStationReport = lazy(() =>
  import("./routes/stationsIce/IceStationReport")
);
const StationsPage = lazy(() =>
  import("./components/StationList/StationsPage")
);
const StationCreator = lazy(() =>
  import("./routes/stationEdition/StationCreator")
);
const StationEdition = lazy(() =>
  import("./routes/stationEdition/StationEdition")
);
const StationEditionList = lazy(() =>
  import("./routes/stationEdition/StationEditionList")
);
const StationEditor = lazy(() =>
  import("./routes/stationEdition/StationEditor")
);
const SolinstEditor = lazy(() =>
  import("./routes/stationEdition/SolinstEditor")
);
const SolinstEdition = lazy(() =>
  import("./routes/stationEdition/SolinstEdition")
);
const SolinstList = lazy(() => import("./routes/stationEdition/SolinstList"));
const SolinstValidationHelper = lazy(() =>
  import("./routes/stationEdition/SolinstValidationHelper")
);
const SolinstValidationList = lazy(() =>
  import("./routes/stationEdition/SolinstValidationList")
);
const SolinstValidation = lazy(() =>
  import("./routes/stationEdition/SolinstValidation")
);
const Connection = lazy(() => import("./routes/persons/Connection"));
const Persons = lazy(() => import("./routes/persons/Persons"));
const Register = lazy(() => import("./routes/persons/Register"));
const Login = lazy(() => import("./routes/persons/Login"));
const Welcome = lazy(() => import("./routes/Welcome"));
const Reset = lazy(() => import("./routes/persons/Reset"));
const ResetDo = lazy(() => import("./routes/persons/ResetDo"));
const ResetRequest = lazy(() => import("./routes/persons/ResetRequest"));
const Profile = lazy(() => import("./routes/persons/Profile"));
const Person = lazy(() => import("./routes/persons/Person"));
const PersonsList = lazy(() => import("./routes/persons/PersonsList"));
const States = lazy(() => import("./routes/statesStations/States"));
const StateCameras = lazy(() => import("./routes/statesStations/StateCameras"));
const StateStations = lazy(() =>
  import("./routes/statesStations/StateStations")
);
const StateRain = lazy(() => import("./routes/statesRain/StateRain"));
const StateFlow = lazy(() => import("./routes/statesFlow/StateFlow"));
const StateLevel = lazy(() => import("./routes/statesFlow/StateLevel"));
const Pegase = lazy(() => import("./routes/pegaseMisc/Pegase"));
const PegaseByWeatherInit = lazy(() =>
  import("./routes/pegaseMisc/PegaseByWeatherInit")
);
const PegaseEnsemble = lazy(() => import("./routes/pegaseMain/PegaseEnsemble"));
const PegaseEvaluationFree = lazy(() =>
  import("./routes/pegaseMisc/PegaseEvaluationFree")
);
const PegaseEvaluationWindow = lazy(() =>
  import("./routes/pegaseMisc/PegaseEvaluationWindow")
);
const PegaseMultiWindowEval = lazy(() =>
  import("./routes/pegaseMisc/PegaseMultiWindowEval")
);
const PegaseMultiple = lazy(() => import("./routes/pegaseMisc/PegaseMultiple"));
const PegasePublic = lazy(() => import("./routes/pegaseMain/PegasePublic"));
const PegaseSingle = lazy(() => import("./routes/pegaseMisc/PegaseSingle"));
const PegaseSummary = lazy(() => import("./routes/pegaseMisc/PegaseSummary"));
//prettier-ignore
const Bulletins = lazy(() => import("./routes/bulletins/Bulletins"));
//prettier-ignore
const CreateBulletin = lazy(() => import("./routes/bulletins/CreateBulletin"));
//prettier-ignore
const BulletinList = lazy(() => import("./routes/bulletins/BulletinList"));
const UpdateBulletin = lazy(() => import("./routes/bulletins/UpdateBulletin"));
//prettier-ignore
const BulletinHistory = lazy(() => import("./routes/bulletins/BulletinHistory"));

const DefaultMap = lazy(() => import("./routes/maps/DefaultMap"));
const PegaseCorrection = lazy(() =>
  import("./routes/pegaseMain/PegaseCorrection")
);

const Gleaubal = lazy(() => import("./routes/gleaubal/Gleaubal"));
const GleaubalBis = lazy(() => import("./routes/gleaubal/GleaubalBis"));
const ObsMap = lazy(() => import("./routes/maps/ObsMap"));

const Lanaudiere = lazy(() => import("./routes/maps/Lanaudiere/Lanaudiere"));
const LanaudierePublic = lazy(() =>
  import("./routes/maps/Lanaudiere/LanaudierePublic")
);
const LanaudiereTable = lazy(() => import("./routes/LanaudiereTable"));
const LanaudiereHydro = lazy(() =>
  import("./routes/maps/Lanaudiere/LanaudiereHydro")
);
const LanaudiereTemp = lazy(() =>
  import("./routes/maps/Lanaudiere/LanaudiereTemp")
);
const LanaudierePrecip24 = lazy(() =>
  import("./routes/maps/Lanaudiere/LanaudierePrecip24")
);
const LanaudierePrecip48 = lazy(() =>
  import("./routes/maps/Lanaudiere/LanaudierePrecip48")
);
const LanaudiereHumidex = lazy(() =>
  import("./routes/maps/Lanaudiere/LanaudiereHumidex")
);
const LanaudiereNBM = lazy(() =>
  import("./routes/maps/Lanaudiere/LanaudiereNBM")
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(err) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error("Rendering error");
    console.error(error);
    console.error(errorInfo.componentStack);
  }
  render() {
    if (this.state.hasError) {
      return (
        <p className={"danger"}>
          Une erreur s'est produite. SVP rechargez la page. Si le problème
          persiste, avertissez votre administratrice système.
        </p>
      );
    }
    return this.props.children;
  }
}

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense fallback={<Waiting />}>
          <Routes>
            <Route path="/naked-obs-map" element={<ObsMap />} />
            <Route path="/" element={<App />}>
              <Route path={"gleaubal-bis"} element={<GleaubalBis />} />
              <Route path={"gleaubal"} element={<Gleaubal />} />
              <Route path={"stations/weather"} element={<WeatherStations />}>
                <Route path={":id"} element={<WeatherStation />} />
                <Route
                  index
                  element={
                    <StationsPage
                      url={"/stations/weather"}
                      storageKey={"soshydro-weather-stations"}
                      title={"Stations météorologiques"}
                      excluderLabel={"station"}
                      Card={TempGauge}
                    />
                  }
                />
              </Route>
              <Route path={"stations/hydro"} element={<HydroStations />}>
                <Route path={":id"} element={<HydroStation />} />
                <Route
                  index
                  element={
                    <StationsPage
                      url={"/stations/hydro"}
                      storageKey={"soshydro-hydro-stations"}
                      title={"Stations hydrologiques"}
                      excluderLabel={"station"}
                      Card={HydroStationCard}
                    />
                  }
                />
              </Route>
              <Route path={"stations/ice"} element={<IceStations />}>
                <Route path={"report"} element={<IceStationReport />} />
                <Route index element={<IceStation />} />
              </Route>
              <Route path="stations/cameras" element={<Cameras />}>
                <Route path={":id"} element={<OneCamera />} />
                <Route
                  index
                  element={
                    <StationsPage
                      url={"/stations/cameras"}
                      storageKey={"soshydro-cameras"}
                      title={"Caméras"}
                      excluderLabel={"caméra"}
                      Card={Camera}
                    />
                  }
                />
              </Route>
              <Route path={"stations/edition"} element={<StationEdition />}>
                <Route path={"new"} element={<StationCreator />} />
                <Route path={":id"} element={<StationEditor />} />
                <Route index element={<StationEditionList />} />
              </Route>
              <Route path={"stations/solinst"} element={<SolinstEdition />}>
                <Route path={":siteSerial"} element={<SolinstEditor />} />
                <Route index element={<SolinstList />} />
              </Route>
              <Route
                path={"stations/solinst/validation"}
                element={<SolinstValidationHelper />}
              >
                <Route path={":id"} element={<SolinstValidation />} />
                <Route index element={<SolinstValidationList />} />
              </Route>
              <Route path="persons" element={<Persons />}>
                <Route path={"connection"} element={<Connection />}>
                  <Route path="register" element={<Register />} />
                  <Route path="login" element={<Login />} />
                </Route>
                <Route path="welcome" element={<Welcome />} />
                <Route path="reset" element={<Reset />}>
                  <Route path="do" element={<ResetDo />} />
                  <Route index element={<ResetRequest />} />
                </Route>
                <Route path="me" element={<Profile />} />
                <Route path={":id"} element={<Person />} />
                <Route index element={<PersonsList />} />
              </Route>
              <Route path="clients" element={<Clients />}>
                <Route path={"new"} element={<ClientCreator />} />
                <Route path={":id"} element={<Client />} />
                <Route index element={<ClientsList />} />
              </Route>
              <Route path="eula" element={<Eula />} />
              <Route path="states" element={<States />}>
                <Route path="rain" element={<StateRain />} />
                <Route path="stations" element={<StateStations />} />
                <Route path="cameras" element={<StateCameras />} />
                <Route path="flow" element={<StateFlow />} />
                <Route path="level" element={<StateLevel />} />
              </Route>
              <Route path="pegase" element={<Pegase />}>
                <Route path={"single"} element={<PegaseSingle />} />
                <Route path={"multiple"} element={<PegaseMultiple />} />
                <Route
                  path={"by-weather-init"}
                  element={<PegaseByWeatherInit />}
                />
                <Route path={"public"} element={<PegasePublic />} />
                <Route path={"ensemble"} element={<PegaseEnsemble />} />
                <Route
                  path={"evaluation-free"}
                  element={<PegaseEvaluationFree />}
                />
                <Route path={"summary"} element={<PegaseSummary />} />
                <Route
                  path={"evaluation-window"}
                  element={<PegaseEvaluationWindow />}
                />
                <Route
                  path={"evaluation-multi-window"}
                  element={<PegaseMultiWindowEval />}
                />
                <Route path={"correction"} element={<PegaseCorrection />} />
              </Route>
              <Route path="bulletins" element={<Bulletins />}>
                <Route path="new" element={<CreateBulletin />} />
                <Route path="update/:id" element={<UpdateBulletin />} />
                <Route path="history" element={<BulletinHistory />} />
                <Route index element={<BulletinList />} />
              </Route>
              <Route index element={<DefaultMap />} />
              <Route path={"lanaudiere"} element={<Lanaudiere />}>
                <Route path={"table"} element={<LanaudiereTable />} />
                <Route path={"map"} element={<LanaudierePublic />} />
                <Route path={"hydro"} element={<LanaudiereHydro />} />
                <Route path={"temperature"} element={<LanaudiereTemp />} />
                <Route
                  path={"precipitation-24"}
                  element={<LanaudierePrecip24 />}
                />
                <Route
                  path={"precipitation-48"}
                  element={<LanaudierePrecip48 />}
                />
                <Route path={"humidex"} element={<LanaudiereHumidex />} />
                <Route path={"nbm-3-days"} element={<LanaudiereNBM />} />
              </Route>
              <Route index element={<ObsMap />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>404!</p>
                  </main>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
