import xhr from "../services/xhr";

export default class Person {
  constructor({
    id,
    firstName,
    lastName,
    email,
    phone,
    permissions,
    clients,
    pocs,
  }) {
    // Reminder that this should NOT be used; use Person.initialize instead
    // but since JS doesn't allow private constructors...
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.permissions = permissions;
    this.clients = clients;
    this.pocs = pocs;
  }

  static initialize(token) {
    const person = new Person(token);
    person.clientsInfos = [];

    const ps = [];
    for (const c of person?.clients || []) {
      ps.push(xhr.get(`/clients/${c}`));
    }

    return Promise.all(ps).then((clients) => {
      for (const c of clients) {
        person.clientsInfos.push(c);
      }
      return person;
    });
  }

  isAdmin() {
    return !!this.permissions?.find((p) => p.name === "admin");
  }

  isScientist() {
    return !!this.permissions?.find(
      (p) => p.name === "scientifique" || p.name === "admin"
    );
  }

  isTechnical() {
    return !!this.permissions?.find(
      (p) => p.name === "technique" || p.name === "admin"
    );
  }

  isHydroMeteo() {
    return !!this?.clients?.includes(1);
  }

  clientHasSub() {
    if (this.isHydroMeteo()) return true;

    let hasSub = false;
    for (const client of this?.clientsInfos || []) {
      for (const service of [
        "iceService",
        "stationsService",
        "weatherService",
      ]) {
        if (
          client?.[service]?.service &&
          client?.[service]?.beginning &&
          client?.[service]?.end
        ) {
          const beg = new Date(client?.[service]?.beginning);
          const end = new Date(client?.[service]?.end);
          if (
            beg.getTime() < new Date().getTime() &&
            new Date().getTime() < end.getTime()
          ) {
            hasSub = true;
            break;
          }
        }
      }
    }
    return hasSub;
  }

  clientHasService(service) {
    if (this.isHydroMeteo()) return true;

    let hasSub = false;
    for (const client of this?.clientsInfos || []) {
      if (
        client?.[service]?.service &&
        client?.[service]?.beginning &&
        client?.[service]?.end
      ) {
        const beg = new Date(client?.[service]?.beginning);
        const end = new Date(client?.[service]?.end);
        if (
          beg.getTime() < new Date().getTime() &&
          new Date().getTime() < end.getTime()
        ) {
          hasSub = true;
          break;
        }
      }
    }
    return hasSub;
  }

  clientHasIceSub() {
    if (this.isHydroMeteo()) return true;
    return this.clientHasService("iceService");
  }

  clientHasStationsSub() {
    if (this.isHydroMeteo()) return true;
    return this.clientHasService("stationsService");
  }

  clientHasWeatherSub() {
    if (this.isHydroMeteo()) return true;
    return (
      this.clientHasService("weatherService") ||
      this.clientHasService("iceService")
    );
  }
}
